var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: _vm.imprTabInfo.gridItem.title,
          columns: _vm.gridItemColumns,
          gridHeight: _vm.gridHeight,
          data: _vm.gridItemData,
          merge: _vm.imprTabInfo.gridItem.merge,
          filtering: false,
          columnSetting: false,
          isTitle: true,
          usePaging: false,
          gridHeightAuto: true,
          editable: !_vm.imprTabInfo.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol" &&
                props.row["sopImprovementIds"] &&
                props.row["ibmStepNames"]
                  ? _vm._l(
                      _vm.$comm.ibmTagItems(props.row),
                      function (item, index) {
                        return _c(
                          "q-chip",
                          {
                            key: index,
                            staticStyle: { "margin-bottom": "4px !important" },
                            attrs: {
                              color: item.color,
                              clickable: true,
                              "text-color": "white",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.linkClick(
                                  props.row,
                                  item.ibmClassCd,
                                  item.sopImprovementId,
                                  "item"
                                )
                              },
                            },
                          },
                          [
                            _vm._v(" " + _vm._s(item.title) + " "),
                            _c("c-assess-tooltip", {
                              attrs: {
                                ibmTooltip: item.ibmTooltip,
                                ibmClassCd: item.ibmClassCd,
                              },
                            }),
                          ],
                          1
                        )
                      }
                    )
                  : _vm._e(),
                [
                  col.name === "customDetail"
                    ? _c("q-btn", {
                        attrs: {
                          flat: "",
                          size: "12px",
                          color: "orange",
                          icon: "chevron_right",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.rowClickDetail(
                              props.row,
                              props.pageIndex
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ],
              ]
            },
          },
        ]),
      }),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "작업요청 목록",
            columns: _vm.gridPlan.columns,
            data: _vm.gridPlan.data,
            usePaging: false,
            isExcelDown: false,
            filtering: false,
            isTitle: true,
            gridHeightAuto: true,
            columnSetting: false,
          },
          on: { linkClick: _vm.linkClick2 },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["woWorkReqStepName"]
                    ? [
                        _c(
                          "q-chip",
                          {
                            attrs: {
                              color: _vm.getColors(props.row.woWorkReqStepCd),
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(props.row.woWorkReqStepName) + " "
                            ),
                          ]
                        ),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "정비요청", icon: "add" },
                        on: { btnClicked: _vm.openPop },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "q-dialog",
        {
          attrs: { position: _vm.positionDetail },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _c(
            "q-card",
            { staticStyle: { width: "100%" } },
            [
              _c("q-linear-progress", { attrs: { value: 1, color: "pink" } }),
              _c("q-card-section", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-12 text-weight-bold-dailog",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("부품"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-8",
                  domProps: {
                    textContent: _vm._s(_vm.selectedRow.checkItemPartName),
                  },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("점검결과"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-12",
                  domProps: { textContent: _vm._s(_vm.dialogcheckResult) },
                }),
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("경고값"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "font",
                      {
                        staticStyle: {
                          "font-size": "1.0em",
                          "font-weight": "700",
                        },
                        attrs: { color: "#0300c1" },
                      },
                      [_vm._v(" " + _vm._s(_vm.selectedRow.gaugeWarnLcl) + " ")]
                    ),
                    _c(
                      "font",
                      {
                        staticStyle: {
                          "font-size": "1.0em",
                          "font-weight": "700",
                        },
                      },
                      [_vm._v(" ~ ")]
                    ),
                    _c(
                      "font",
                      {
                        staticStyle: {
                          "font-size": "1.0em",
                          "font-weight": "700",
                        },
                        attrs: { color: "#C10015" },
                      },
                      [_vm._v(" " + _vm._s(_vm.selectedRow.gaugeWarnUcl) + " ")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-2" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("실행값"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "col-4" },
                  [
                    _c(
                      "font",
                      {
                        staticStyle: {
                          "font-size": "1.0em",
                          "font-weight": "700",
                        },
                        attrs: { color: "#0300c1" },
                      },
                      [_vm._v(" " + _vm._s(_vm.selectedRow.gaugeExecLcl) + " ")]
                    ),
                    _c(
                      "font",
                      {
                        staticStyle: {
                          "font-size": "1.0em",
                          "font-weight": "700",
                        },
                      },
                      [_vm._v(" ~ ")]
                    ),
                    _c(
                      "font",
                      {
                        staticStyle: {
                          "font-size": "1.0em",
                          "font-weight": "700",
                        },
                        attrs: { color: "#C10015" },
                      },
                      [_vm._v(" " + _vm._s(_vm.selectedRow.gaugeExecUcl) + " ")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("판정기준"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-12",
                  domProps: { textContent: _vm._s(_vm.dialogdecisionBasis) },
                }),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", { staticClass: "mini-dailog-title" }, [
                    _vm._v("점검시 안전사항"),
                  ]),
                ]),
                _c("div", {
                  staticClass: "col-12",
                  domProps: { textContent: _vm._s(_vm.dialogcheckCaution) },
                }),
                _c(
                  "div",
                  { staticClass: "col-12 mini-dailog-btn-impr" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: {
                            showLoading: false,
                            label: "닫기",
                            icon: "close",
                          },
                          on: { btnClicked: _vm.dialogClose },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }